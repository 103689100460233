var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container sat-list"},[_c('SatMenu',{attrs:{"active":"5"}}),_c('div',{staticClass:"sat-header"},[_c('PageTitle',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("toefl.index.title_new"))+" ")])])],1),_c('hr'),_c('el-table',{staticClass:"tests-list",staticStyle:{"width":"100%","margin-bottom":"30px"},attrs:{"data":_vm.tests,"border":"","height":"500"}},[_c('el-table-column',{attrs:{"label":"Test Name","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_exam_id > 0)?_c('router-link',{attrs:{"to":{
            name: 'TestResult',
            query: {
              user_exam_id: scope.row.user_exam_id
            },
            params: { testType: _vm.testType }
          },"tag":"a"}},[_vm._v(" "+_vm._s(scope.row.full_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(scope.row.full_name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Test ID","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_exam_id > 0)?_c('router-link',{attrs:{"to":{
            name: 'TestResult',
            query: {
              user_exam_id: scope.row.user_exam_id
            },
            params: { testType: _vm.testType }
          },"tag":"a"}},[_vm._v(" "+_vm._s(scope.row.title)+" ")]):_c('span',[_vm._v(" "+_vm._s(scope.row.title)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Last Test"}},[_c('el-table-column',{attrs:{"label":"Student","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_exam_id > 0)?_c('router-link',{attrs:{"to":{ name: 'SatTestResults' }}},[_vm._v(" "+_vm._s(_vm.userMethods.displayName( scope.row.first_name, scope.row.last_name ))+" ")]):_c('span',[_vm._v(" N/A ")])]}}])}),_c('el-table-column',{attrs:{"label":"Date","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_exam_id > 0)?_c('span',[_c('router-link',{attrs:{"to":{ name: 'SatTestResults' }}},[_vm._v(" "+_vm._s(_vm.instant.formatDateTime(scope.row.date))+" ")])],1):_c('span',[_vm._v(" N/A ")])]}}])})],1),_c('el-table-column',{attrs:{"label":"Students","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.student_test_count > 0)?_c('span',[_c('router-link',{attrs:{"to":{ name: 'SatTestResults' }}},[_c('b',[_vm._v(_vm._s(scope.row.student_test_count))])])],1):_c('span',[_vm._v(" N/A ")])]}}])}),_c('el-table-column',{attrs:{"label":"Analysis","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.exam_id && scope.row.student_test_count > 0)?_c('router-link',{staticStyle:{"margin-right":"5px"},attrs:{"to":{
            path: '/analysis/' + _vm.testType,
            query: {
              type: _vm.testType,
              test: scope.row[_vm.testType + '_id']
            }
          },"tag":"a"}},[_c('i',{staticClass:"fas fa-chart-bar"})]):_c('span',[_vm._v(" N/A ")])]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"Action","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"action-icon",attrs:{"to":{ name: 'Examination' }}},[_c('i',{staticClass:"fa fa-eye"})]),_c('a',{staticClass:"action-icon",attrs:{"href":'/' +
              _vm.testType +
              '/answers?exam_id=' +
              scope.row[_vm.testType + '_id'] +
              '&can_edit=1'}},[_c('i',{staticClass:"fa fa-edit"})]),_c('el-tooltip',{staticClass:"item",attrs:{"content":"批改","placement":"top","popper-class":"tooltipColor"}},[_c('a',{staticClass:"action-icon",attrs:{"href":`/tests/grading/sat?keyboard=2&exam_id=${scope.row.exam_id}`}},[_c('i',{staticClass:"fa fa-superscript"})])]),_c('el-tooltip',{staticClass:"item",attrs:{"content":"刪除","placement":"top","popper-class":"tooltipColor"}},[_c('span',[_c('i',{staticClass:"fas fa-trash-alt warning-icon"})])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }